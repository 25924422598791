import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'

const CourseTextContent: FunctionComponent = ({ children }) => {
  return (
    <Container>
      {children}
      {/* {image && <img src={image} alt={imageAlt} />} */}
    </Container>
  )
}

export default CourseTextContent

const Container = styled.div`
  margin-bottom: 57px;

  @media (min-width: 1024px) {
    margin-bottom: 71px;
  }
  & span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: ${theme.grey.inputHover};
    display: inline-block;
    margin-bottom: 17px;

    @media (min-width: 1024px) {
      margin-bottom: 22px;
    }
  }

  & h3 {
    margin-bottom: 28px;

    @media (min-width: 1024px) {
      margin-bottom: 34px;
    }
  }

  & h4 {
    margin: 20px 0 15px;

    @media (min-width: 1024px) {
      margin: 34px 0 20px;
    }
  }
  & h5 {
    margin: 0px 0 15px;

    @media (min-width: 1024px) {
      margin: 0px 0 26px;
    }
  }
  & p {
    font-weight: 300;
    margin-bottom: 14px;
  }

  & img {
    width: auto;
    max-width: 200px;
  }
`
