import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import { CourseTextContentWithImageProps } from './courseTypes'

const CourseTextContentWithImage = ({
  content,
  span,
  heading,
  image,
  imageAlt,
}: CourseTextContentWithImageProps) => {
  return (
    <Container>
      {span && <span>{span}</span>}
      {heading && <h3>{heading}</h3>}
      {image && <img src={image} alt={imageAlt} />}
      {content &&
        content.map((item, key) => {
          return <p key={key}>{item}</p>
        })}
    </Container>
  )
}

export default CourseTextContentWithImage

const Container = styled.div`
  margin-bottom: 57px;

  @media (min-width: 1024px) {
    margin-bottom: 71px;
  }
  & span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: ${theme.grey.inputHover};
    display: inline-block;
    margin-bottom: 17px;

    @media (min-width: 1024px) {
      margin-bottom: 22px;
    }
  }

  & h3 {
    margin-bottom: 24px;
  }

  & h4 {
    margin: 20px 0 15px;

    @media (min-width: 1024px) {
      margin: 34px 0 20px;
    }
  }
  & h5 {
    margin: 0px 0 15px;

    @media (min-width: 1024px) {
      margin: 0px 0 26px;
    }
  }
  & p {
    font-weight: 300;
    margin-bottom: 14px;
  }

  & img {
    margin-bottom: 37px;
    width: 100%;
    max-width: 200px;
    @media (min-width: 1024px) {
      margin-bottom: 30px;
    }
  }
`
