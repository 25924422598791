import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { appendUrlPartnerTracking } from '../../util/url'
import theme from '../styling/theme'

import logo1 from '../../buildAssets/logos/culture/logo-culture.png'
import logo2 from '../../buildAssets/logos/compliance/logo-compliance.png'
import logo3 from '../../buildAssets/logos/phishing/logo-phishing.png'

interface CourseIncludedProps {
  culture: boolean
  phishing: boolean
  compliance: boolean
  elements: JSX.Element[] | JSX.Element
}

const CourseIncluded = ({
  culture,
  compliance,
  phishing,
  elements,
}: CourseIncludedProps) => {
  const getGridWidth = () => {
    if (culture && compliance && phishing) {
      return 3
    }
    return 2
  }

  return (
    <Container>
      <Col>
        <AvailableWith>Included in…</AvailableWith>
        {/* {icons && icons.length > 0 && ( */}
        <Grid gridWidth={getGridWidth()}>
          {/* {icons.map((item, key: number) => {
              if (key < 3) {
                return (
                  <Mask
                    key={key}
                    href={appendUrlPartnerTracking(item.url)}
                    borderLeft={key > 0}
                  >
                    <Img src={item.icon} alt={item.iconAlt} />
                  </Mask>
                )
              }
            })} */}

          {culture && (
            <Mask
              borderLeft={false}
              href={appendUrlPartnerTracking('/products/bobs-culture')}
            >
              <Img src={logo1} alt={"Bob's Culture"} />
            </Mask>
          )}
          {compliance && (
            <Mask
              borderLeft={true}
              href={appendUrlPartnerTracking('/products/bobs-compliance')}
            >
              <Img src={logo2} alt={"Bob's Compliance"} />
            </Mask>
          )}
          {phishing && (
            <Mask
              borderLeft={true}
              to={appendUrlPartnerTracking('/products/bobs-phishing')}
            >
              <Img src={logo3} alt={"Bob's Phishing"} />
            </Mask>
          )}
        </Grid>
        {/* )} */}
      </Col>
      <ColText>{elements}</ColText>
    </Container>
  )
}

export default CourseIncluded

const Container = styled.section`
  padding: 55px 0 40px;
  border-bottom: 1px solid ${theme.grey.keyline};
  @media (min-width: 768px) {
    padding: 55px 0 64px;
    display: flex;
    justify-content: space-between;
  }
`
const Col = styled.div`
  @media (min-width: 768px) {
    width: 50%;
  }
`

const ColText = styled(Col)`
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 13px;
    @media (min-width: 768px) {
      margin-bottom: 19px;
    }
  }
  @media (min-width: 768px) {
    width: 50%;
    margin-left: 30px;
  }
`

const AvailableWith = styled.h5`
  margin-bottom: 24px;

  @media (min-width: 768px) {
    margin-bottom: 27px;
  }
`

interface GridProps {
  gridWidth: number
}
const Grid = styled.div<GridProps>`
  display: flex;
  border-top: 1px solid ${theme.grey.keyline};
  border-bottom: 1px solid ${theme.grey.keyline};
  margin-bottom: 26px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`

interface MaskProps {
  borderLeft: boolean
}
const Mask = styled(Link)<MaskProps>`
  &,
  &:link,
  &:visited {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    height: 95px;
    width: 100%;
    max-width: 350px;
    padding: 0 0px;
    transition: background 0.3s ease;
    border-left: ${(props) =>
      props.borderLeft ? `1px solid ${theme.grey.keyline}` : 'none'};
  }
  &:hover,
  &:active {
    background: ${theme.brand.blue};
  }

  @media (min-width: 768px) {
    padding: 0 22px;
    max-width: 200px;
  }
`

const Img = styled.img`
  max-height: 50px;
  max-width: 80%;
`

const P = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`
