import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import Button from '../shared/Button/Button'
import { CourseDescriptionProps } from './courseTypes'
import { appendUrlPartnerTracking } from '../../util/url'

const links = {
  ctaTitle1: 'Get started',
  ctaUrl1: '/pricing',
  ctaTitle2: 'Get in touch',
  ctaUrl2: '/contact-us/',
}

const CourseDescription = ({
  title,
  description,
  icon,
  iconAlt,
}: CourseDescriptionProps) => {

  return (
    <Container>
      <Title>{title}</Title>
      {description && (
        <Description className="hero-p">{description}</Description>
      )}
      {links.ctaTitle1 !== '' && (
        <ButtonContainer>
          {links.ctaUrl1 && (
            <Button href={appendUrlPartnerTracking(links.ctaUrl1)}>
              {links.ctaTitle1}
            </Button>
          )}
          {links.ctaUrl2 && (
            <Button href={appendUrlPartnerTracking(links.ctaUrl2)} secondary>
              {links.ctaTitle2}
            </Button>
          )}
        </ButtonContainer>
      )}
      {icon && <Icon src={icon} alt={iconAlt} />}
    </Container>
  )
}

export default CourseDescription

const Container = styled.section`
  border-bottom: 3px solid ${theme.grey.courseTemplate};
`

const Title = styled.h1`
  margin-bottom: 30px;

  @media (min-width: 1024px) {
    margin-bottom: 37px;
  }
`
const Description = styled.p``

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 420px;
  margin-top: 33px;
  margin-bottom: 23px;
  & a:first-of-type {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin-top: 45px;
    & a:first-of-type {
      margin-bottom: 0px;
    }
  }
  @media (min-width: 1024px) {
    max-width: 420px;
    margin-top: 40px;
    margin-bottom: 27px;
  }
`
const Icon = styled.img`
  width: 150px;
  margin-bottom: 55px;

  @media (min-width: 1024px) {
    width: 200px;
    margin-bottom: 60px;
  }
`
