import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import MainGrid from '../styling/MainGrid'

const CourseTemplateLayout: FunctionComponent = ({ children }) => {
  return (
    <MainGrid>
      <Container>{children}</Container>
    </MainGrid>
  )
}

export default CourseTemplateLayout

const Container = styled.div`
  display: flex;
  padding: 65px 0 0;
`
