import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

const CourseRightCol: FunctionComponent = ({ children }) => {
  return <Container>{children}</Container>
}

export default CourseRightCol

const Container = styled.div`
  width: 100%;
  padding-left: 0;

  @media (min-width: 1024px) {
    padding-left: 11%;
  }
`
