import React, { useState } from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import Button from '../shared/Button/Button'
import { scrollTo } from '../../util/functions'
// import InlineLink from '../shared/InlineLink'
import { CourseMethodologyProps } from './courseTypes'
import { appendUrlPartnerTracking } from '../../util/url'

const CourseMethodology = ({
  content,
  cardsContent,
}: CourseMethodologyProps) => {
  const [showAllCards, toggleCards] = useState(false)
  const loadMore = () => {
    if (showAllCards) {
      setTimeout(() => {
        scrollTo('course-methodology-cards')
      }, 300)
    }
    toggleCards(!showAllCards)
  }
  return (
    <Container>
      {content}

      <GridMobile id="course-methodology-cards">
        {cardsContent.length > 0 &&
          cardsContent.map((item, key) => {
            if (key < 1 || showAllCards) {
              return (
                <Card>
                  {item.image && <CardImg src={item.image} alt={item.alt} />}
                  {item.title && <CardTitle>{item.title}</CardTitle>}
                  {item.body && <CardBody>{item.body}</CardBody>}
                </Card>
              )
            }
          })}
        <BtnContainer>
          <Button onClick={loadMore} secondary>
            {!showAllCards ? 'Show more' : 'Show less'}
          </Button>
        </BtnContainer>
      </GridMobile>

      {cardsContent.length > 0 && (
        <>
          <Grid>
            {cardsContent.map((item, key) => {
              return (
                <Card key={key}>
                  {item.image && <CardImg src={item.image} alt={item.alt} />}
                  {item.title && <CardTitle>{item.title}</CardTitle>}
                  {item.body && <CardBody>{item.body}</CardBody>}
                </Card>
              )
            })}
          </Grid>
          {/* <InlineLink
            href={appendUrlPartnerTracking('/courses')}
            text="Learn more about our courses"
            margintop="25px"
            margintopDesktop="34px"
            icon
          /> */}
        </>
      )}
    </Container>
  )
}

export default CourseMethodology

const Container = styled.div`
  margin-bottom: 57px;

  @media (min-width: 1024px) {
    margin-bottom: 20px;
  }
  & span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: ${theme.grey.inputHover};
    display: inline-block;
    margin-bottom: 17px;

    @media (min-width: 1024px) {
      margin-bottom: 22px;
    }
  }

  & h3 {
    margin-bottom: 28px;

    @media (min-width: 1024px) {
      margin-bottom: 34px;
    }
  }

  & h4 {
    margin: 20px 0 15px;

    @media (min-width: 1024px) {
      margin: 34px 0 20px;
    }
  }
  & h5 {
    margin: 0px 0px 15px;

    @media (min-width: 1024px) {
      margin: 0px 0px 26px;
    }
  }
  & p {
    font-weight: 300;
    margin-bottom: 14px;
  }
`

const CardTitle = styled.h5`
  margin-bottom: 15px !important;
  @media (min-width: 768px) {
    margin-bottom: 11px !important;
  }
`

const CardBody = styled.p``

const Grid = styled.div`
  display: none;
  @media (min-width: 768px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    height: 100%;
    margin: 46px 0 40px 0;
  }
`
const GridMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 37px;
  @media (min-width: 768px) {
    display: none;
  }
`

const Card = styled.div`
  padding: 58px 28px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  text-align: center;
  background: ${theme.background.grey};
  border-bottom: 3px solid ${theme.brand.blue};
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    padding: 58px 30px 30px;
    &:not(:last-of-type) {
      margin-bottom: 0px;
    }
  }

  @media (min-width: 768px) {
    /* width: 30%; */
  }
`

const CardImg = styled.img`
  width: auto;
  height: 60px;
  margin-bottom: 23px;
`
const BtnContainer = styled.div`
  margin-top: 20px;
`
