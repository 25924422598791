import React from 'react'
import { graphql } from 'gatsby'
import TrackVisibility from 'react-on-screen'
import Layout from '../components/Layout'
import CourseTemplateLayout from '../components/CourseTemplate/CourseTemplateLayout'
import PromotionBannerAnimation from '../components/PromotionBannerAnimation'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import CourseLeftCol from '../components/CourseTemplate/CourseLeftCol'
import CourseDescription from '../components/CourseTemplate/CourseDescription'
import CourseRightCol from '../components/CourseTemplate/CourseRightCol'
import gchq from '../buildAssets/logos/general/NCSC-Certified.png'
import CourseIncluded from '../components/CourseTemplate/CourseIncluded'
import icon1 from '../images/easy.png'
import icon2 from '../images/quality-course-design.png'
import icon3 from '../images/online-device-learning.png'
import icon4 from '../images/pack.png'
import CourseOverview from '../components/CourseTemplate/CourseOverview'
import CourseTextContent from '../components/CourseTemplate/CourseTextContent'
import CourseTextContentWithImage from '../components/CourseTemplate/CourseTextContentWithImage'
import CourseMethodology from '../components/CourseTemplate/CourseMethodology'

import parse from 'html-react-parser'
import MainGrid from '../components/styling/MainGrid'
import { LazyBlocksFactory } from '../components/LazyBlocksFactory/LazyBlocksFactory'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Courses',
    url: '/courses',
  },
  {
    title: 'Phishing Fears',
    url: '/phishing-fears',
  },
]

const courseMethodology = [
  <>
    <span>Our learning methodology</span>
    <h3>The Bob’s Business way</h3>
    <p>
      Bob’s Business courses are built on the twin principles of behavioural
      science and psychology to deliver truly exceptional results to
      organisations of all sizes, across the public and private sectors.
    </p>
  </>,
]

const cardsContent = [
  {
    image: icon1,
    title: 'Simple, actionable advice',
    alt: 'Simple, actionable advice',
    body:
      'We choose to use clear language and simple, actionable advice in our courses to help lock in positive behaviours.',
  },
  {
    image: icon2,
    title: 'High-quality course design',
    alt: 'High-quality course design',
    body:
      'Our courses are built in house by our dedicated team and use animations, storytelling and a cast of unique characters for training people actually want to take.',
  },
  {
    image: icon3,
    title: 'Online learning',
    alt: 'Online learning',
    body:
      'Every Bob’s Business course is powered by online learning and hosted on an included LMS, which enables users to take our courses at any time, on any device.',
  },
  {
    image: icon4,
    title: 'Included reinforcement',
    alt: 'Included reinforcement',
    body:
      'Each course comes with a dedicated Reinforcement Pack, with up-to 18 included materials like screensavers, blog posts, email footers and more to drive home the key messages of each course and help make new behaviours permanent.',
  },
]

interface Props {
  data: any
  pageContext: any
}
const CourseTemplate = ({ data, pageContext }: Props) => {
  const { wpCourse: courseData } = data
  const { acfCourse2: courseData2 } = courseData

  const renderedBlocks = LazyBlocksFactory(
    courseData.lazy_data,
    courseData.title
  )

  console.log(courseData)
  return (
    <Layout>
      <MainGrid noPaddingMobile noPaddingTablet backgroundMobile="white">
        <Breadcrumb
          data={[
            {
              title: 'Home',
              url: '/',
            },
            {
              title: 'Courses',
              url: '/courses',
            },
            {
              title: courseData.title,
              url: '/',
            },
          ]}
        />

        {/* <Breadcrumb data={breadcrumbData} /> */}
      </MainGrid>
      {renderedBlocks.map((v) => v)}
      <CourseTemplateLayout>
        <CourseLeftCol
          title={courseData.title}
          description={courseData2.description}
        />
        <CourseRightCol>
          <CourseDescription
            title={courseData.title}
            description={courseData2.description}
            icon={courseData2.accreditations ? gchq : null}
            iconAlt={courseData2.accreditations ? 'GCHQ Certified' : null}
          />
          {/*@TODO not sure how we're going to manage the icons here...*/}

          <CourseIncluded
            compliance={courseData2.includedCompliance}
            culture={courseData2.includedCulture}
            phishing={courseData2.includedPhishing}
            elements={parse(courseData2.courseIncluded)}
          />

          <CourseOverview
            topHeading="Course overview"
            heading="About the course"
            courseOverviewData={parse(courseData2.courseOverviewContent)}
            imgDesktop={courseData2.courseOverviewImageDesktop}
            imgMobile={courseData2.courseOverviewImageMoblie}
            description={courseData2.courseOverviewDescription}
            caption={courseData2.courseOverviewImageCaption}
          />
          <CourseTextContent>
            {parse(courseData2.courseContent)}
          </CourseTextContent>
          {courseData2.accreditations ? (
            <CourseTextContentWithImage
              span="Recognised excellence"
              heading="Course accreditations"
              content={[
                <p>
                  The {courseData.title} course is accredited by the National
                  Cyber Security Centre.
                </p>,
              ]}
              image={gchq}
              imageAlt="In association with National Cyber Security Center"
            />
          ) : null}

          <CourseMethodology
            content={courseMethodology}
            cardsContent={cardsContent}
          />
        </CourseRightCol>
      </CourseTemplateLayout>
    </Layout>
  )
}

export default CourseTemplate

export const pageQuery = graphql`
  query PostById($id: String!) {
    wpCourse(id: { eq: $id }) {
      title
      id
      lazy_data
      acfCourse2 {
        accreditations
        description
        fieldGroupName
        courseIncluded
        includedCompliance
        includedCulture
        includedPhishing

        courseOverviewContent
        courseOverviewDescription
        courseOverviewImageCaption
        courseContent
        courseOverviewImageDesktop {
          sourceUrl
          caption
          altText
          description
        }
        courseOverviewImageMoblie {
          sourceUrl
          caption
          altText
          description
        }
      }
    }
  }
`
