import React from 'react'
import styled from 'styled-components'
import { TopHeading } from '../SolutionsCards/SolutionsCards'
import { CourseOverviewProps } from './courseTypes'

function TempImageUrlFix(url: string) {
  const b = 'wordpress.'
  const position = 8
  const output = [url.slice(0, position), b, url.slice(position)].join('')
  return output
}

const CourseOverview = ({
  topHeading,
  heading,
  courseOverviewData,
  imgDesktop,
  imgMobile,
  description,
  caption,
}: CourseOverviewProps) => {
  return (
    <Container>
      {topHeading && (
        <TopHeading className="small-p" marginBottom="15px">
          {topHeading}
        </TopHeading>
      )}
      {heading && <Heading>{heading}</Heading>}
      {courseOverviewData && <Content>{courseOverviewData}</Content>}
      {imgMobile && (
        <MoblieContainer>
          <ImgMobile
            src={imgMobile.sourceUrl}
            alt={imgMobile.altText}
          />
          <figcaption>{caption}</figcaption>
          <Description>{description}</Description>
        </MoblieContainer>
      )}
      {imgDesktop && (
        <DesktopContainer>
          <ImgDesktop
            src={imgDesktop.sourceUrl}
            alt={imgDesktop.altText}
          />
          <figcaption>{caption}</figcaption>
          <Description>{description}</Description>
        </DesktopContainer>
      )}
    </Container>
  )
}
export default CourseOverview

const Container = styled.div`
  padding: 65px 0 25px;

  @media (min-width: 1024px) {
    padding: 65px 0 35px;
  }

  & figcaption {
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
    margin-top: 30px;

    @media (min-width: 1024px) {
      margin-top: 18px;
    }
  }
`
const Heading = styled.h2`
  margin-bottom: 24px;
`
const Content = styled.div`
  font-weight: 300;
`
const ImgMobile = styled.img`
  margin-top: 45px;
  @media (min-width: 1024px) {
    display: none;
  }
`

const DesktopContainer = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
`

const MoblieContainer = styled.div`
  @media (min-width: 1024px) {
    display: none;
  }
`
const ImgDesktop = styled.img`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    margin-top: 45px;
  }
`
const Description = styled.p`
  font-weight: 300;
  margin-top: 35px;

  @media (min-width: 1024px) {
    margin-top: 42px;
  }
`
