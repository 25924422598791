import React from 'react'
import styled from 'styled-components'
import theme from '../styling/theme'
import arrhead from '../../images/back-left-arrow.svg'
import { CourseLeftColumnProps } from './courseTypes'
import { appendUrlPartnerTracking } from '../../util/url'
import { Link } from 'gatsby'

const CourseleftCol = ({ title, description }: CourseLeftColumnProps) => {
  return (
    <Container>
      <Inner>
        <Title>
          <Course>Course:</Course>
          <Name>{title}</Name>
        </Title>
        <BackLink to={appendUrlPartnerTracking('/courses')}>
          <Arrow src={arrhead} alt="Arrow back" />
          <Line />
        </BackLink>
        {description && (
          <Description className="small-p">{description}</Description>
        )}
      </Inner>
    </Container>
  )
}

export default CourseleftCol

const Container = styled.div`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    width: 100%;
    max-width: 264px;
    position: relative;
  }
`
const Inner = styled.div`
  position: sticky;
  left: 0;
  bottom: 0;
  top: 100px;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

const Course = styled.h6`
  color: ${theme.text.second};
`

const Name = styled.h5`
  font-weight: 300;
  display: block;
  margin-top: -4px;
`
const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 280px;
  margin-bottom: 18px;
`

const Arrow = styled.img`
  height: 40px;
  transition: all 0.5s ease;
  position: relative;
  left: -10px;
  animation: arrowCourseTemplate ease 0.5s forwards 0.5s;
  ${BackLink}:hover & {
    transform: translateX(-20px);
  }
`

const Line = styled.div`
  display: inline;
  position: relative;
  width: 0%;
  height: 1px;
  background: ${theme.brand.blue};
  transition: all 0.5s ease;
  animation: growLineCourseTemplate ease 0.5s forwards 0.5s;
  ${BackLink}:hover & {
    transform: translateX(-40px);
  }
`

const Description = styled.p`
  color: ${theme.text.second};
  font-weight: 300;
`
